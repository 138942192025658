import { graphql, useStaticQuery } from 'gatsby'
import { IconName } from '../../icons'

interface Site {
  siteMetadata: {
    name: string
    title: string
    description: string
    author?: string
    contact: {
      email: string
      phoneNumber: string
      formattedPhoneNumber: string
      address: {
        url: string
        name: string
      }
    }
    social: {
      icon: IconName
      name: string
      link: string
    }[]
  }
}

type QueryResult = {
  site: Site
}

export const useSite = (): Site => {
  const { site } = useStaticQuery<QueryResult>(
    graphql`
      query {
        site {
          siteMetadata {
            name
            title
            description
            author
            contact {
              email
              phoneNumber
              formattedPhoneNumber
              address {
                url
                name
              }
            }
            social {
              icon
              name
              link
            }
          }
        }
      }
    `
  )

  return site
}

export const useSiteMetadata = (): Site['siteMetadata'] => {
  const { siteMetadata } = useSite()
  return siteMetadata
}
