import classNames from 'classnames'
import Button from '../../../button'
import Link from '../../../link'
import {
  navbarNav,
  navbarNavExpanded,
  navbarNavLink,
  navbarNavOverlay,
} from './navbar-nav.module.scss'

interface NavbarLinksProps {
  isExpanded?: boolean
  className?: string
  onClickLink?: () => void
  onClickOverlay?: () => void
}

const getLinks = ({ onClickLink }: Pick<NavbarLinksProps, 'onClickLink'>) =>
  [
    {
      to: '/services',
      content: 'Services',
    },
    {
      to: '/work',
      content: 'Work',
    },
    {
      to: '/about',
      content: 'About',
    },
  ]
    .map(link => (
      <Link
        key={link.to}
        to={link.to}
        color="inherit"
        className={navbarNavLink}
        onClick={onClickLink}
      >
        {link.content}
      </Link>
    ))
    .concat(
      <Button
        key="/contact"
        variant="primary"
        link={{ to: '/contact' }}
        onClick={onClickLink}
      >
        Get in touch
      </Button>
    )

const NavbarNav = ({
  isExpanded,
  className,
  onClickLink,
  onClickOverlay,
}: NavbarLinksProps) => (
  <>
    <nav
      className={classNames(navbarNav, className, {
        [navbarNavExpanded]: isExpanded,
      })}
      role="navigation"
      aria-labelledby="primary-navigation"
    >
      {getLinks({ onClickLink })}
    </nav>
    {isExpanded && (
      // eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events, jsx-a11y/control-has-associated-label
      <div
        role="button"
        onClick={onClickOverlay}
        className={navbarNavOverlay}
      />
    )}
  </>
)

export default NavbarNav
