import classNames from 'classnames'
import { Link as GastbyLink } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { MouseEventHandler, ReactNode } from 'react'
import {
  link,
  linkDefault,
  linkInherit,
  linkUnderline,
} from './link.module.scss'

export interface LinkProps {
  to?: string
  href?: string
  color?: 'default' | 'inherit'
  underline?: boolean
  external?: boolean
  className?: string
  children: ReactNode
  onClick?: MouseEventHandler
  onMouseEnter?: MouseEventHandler
  onMouseLeave?: MouseEventHandler
}

const Link = ({
  to,
  color,
  underline,
  external,
  className,
  href,
  children,
  ...rest
}: LinkProps) => {
  const classes = classNames(link, className, {
    [linkDefault]: color === 'default',
    [linkInherit]: color === 'inherit',
    [linkUnderline]: underline,
  })
  if (href) {
    const HrefComponent = external ? OutboundLink : 'a'
    return (
      <HrefComponent
        {...rest}
        className={classes}
        href={href}
        rel="noreferrer"
        target={external ? '_blank' : undefined}
      >
        {children}
      </HrefComponent>
    )
  }
  if (to)
    return (
      <GastbyLink {...rest} to={to} className={classes}>
        {children}
      </GastbyLink>
    )
  return null
}

export default Link
