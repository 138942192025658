import { graphql, useStaticQuery } from 'gatsby'
import { ImageDataLike, getImage } from 'gatsby-plugin-image'
import Work from '../../models/work'

type QueryResult = {
  allWork: {
    edges: {
      node: Omit<Work, 'image'> & { image: ImageDataLike }
    }[]
  }
}

const useWorks = (): Work[] => {
  const { allWork } = useStaticQuery<QueryResult>(
    graphql`
      query {
        allWork(sort: { fields: [position], order: ASC }) {
          edges {
            node {
              id
              name
              description
              position
              link: gatsbyPath(filePath: "/work/{Work.name}")
              categories
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    `
  )

  return allWork.edges.map(({ node }) => ({
    ...node,
    image: getImage(node.image),
  }))
}

export default useWorks
