import { PropsWithChildren } from 'react'
import Footer from '../footer'
import Navbar from '../navbar'
import { layoutContent } from './layout.module.scss'

const Layout = ({ children }: PropsWithChildren<Record<string, never>>) => (
  <>
    <Navbar />
    <main className={layoutContent}>{children}</main>
    <Footer />
  </>
)

export default Layout
