import { HTMLAttributes, PropsWithChildren, Ref, forwardRef } from 'react'

export interface ElementProps
  extends PropsWithChildren<HTMLAttributes<HTMLElement>> {
  as?: keyof JSX.IntrinsicElements
}

const Element = forwardRef<HTMLElement, ElementProps>(
  ({ as: Tag = 'div', ...rest }: ElementProps, ref: Ref<HTMLElement>) => (
    <Tag {...rest} ref={ref} />
  )
)

export default Element
