import classNames from 'classnames'
import ButtonBase, { ButtonBaseProps } from './components/button-base'
// eslint-disable-next-line import/order
import { button, buttonPrimary, buttonStandard } from './button.module.scss'

interface ButtonProps extends ButtonBaseProps {
  variant?: 'standard' | 'primary'
}

const Button = ({
  variant = 'standard',
  className,
  children,
  ...rest
}: ButtonProps) => (
  <ButtonBase
    {...rest}
    className={classNames(className, button, {
      [buttonStandard]: variant === 'standard',
      [buttonPrimary]: variant === 'primary',
    })}
  >
    {children}
  </ButtonBase>
)

export default Button
