import { useState } from 'react'
import { useSiteMetadata } from '../../hooks/site'
import { ButtonBase } from '../button'
import Container from '../container'
import Icon from '../icon'
import Link from '../link'
import Typography from '../typography'
import NavbarNav from './components/navbar-nav'
import { navbar, navbarContainer, navbarMenuIcon } from './navbar.module.scss'

const Navbar = () => {
  const { name } = useSiteMetadata()
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleNav = () => setIsExpanded(!isExpanded)

  return (
    <header className={navbar}>
      <Container className={navbarContainer}>
        <ButtonBase className={navbarMenuIcon} onClick={toggleNav}>
          <Icon name="menu" />
        </ButtonBase>
        <Link to="/">
          <Typography variant="h5">{name}</Typography>
        </Link>
        <NavbarNav
          isExpanded={isExpanded}
          onClickLink={toggleNav}
          onClickOverlay={toggleNav}
        />
      </Container>
    </header>
  )
}

export default Navbar
