import classNames from 'classnames'
import { CSSProperties } from 'react'
import icons, { IconName } from '../../icons'
import { iconColorPrimary, iconColorWhite } from './icon.module.scss'

export interface IconProps {
  name: IconName
  width?: number
  height?: number
  size?: number
  style?: CSSProperties
  className?: string
  color?: 'default' | 'primary' | 'secondary' | 'destructive' | 'white'
}

const Icon = ({
  name,
  color,
  className,
  size,
  width,
  height,
  ...rest
}: IconProps) => {
  const Element = icons[name]

  return (
    <Element
      {...rest}
      width={width || size}
      height={height || size}
      className={classNames(className, {
        [iconColorWhite]: color === 'white',
        [iconColorPrimary]: color === 'primary',
      })}
    />
  )
}
export default Icon
