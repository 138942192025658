import Android from './android.svg'
import AppFlow from './app-flow.svg'
import CodeAudit from './code-audit.svg'
import Code from './code.svg'
import CTO from './cto.svg'
import Ember from './ember.svg'
import Facebook from './facebook.svg'
import Fintech from './fintech.svg'
import Github from './github.svg'
import Hexagon from './hexagon.svg'
import Instagram from './instagram.svg'
import iOS from './ios.svg'
import LinkedIn from './linkedin.svg'
import Menu from './menu.svg'
import MobileDev from './mobile-dev.svg'
import NodeJS from './nodejs.svg'
import ProjectManagement from './project-management.svg'
import QualityAssurance from './quality-assurance.svg'
import ReactJS from './reactjs.svg'
import Responsive from './responsive.svg'
import Result from './result.svg'
import TeamUp from './team-up.svg'
import Training from './training.svg'
import TrendingFlat from './trending-flat.svg'
import Twitter from './twitter.svg'
import UserExperience from './user-experience.svg'

const icons = {
  cto: CTO,
  hexagon: Hexagon,
  instagram: Instagram,
  twitter: Twitter,
  facebook: Facebook,
  code: Code,
  menu: Menu,
  nodejs: NodeJS,
  responsive: Responsive,
  result: Result,
  linkedin: LinkedIn,
  training: Training,
  fintech: Fintech,
  github: Github,
  reactjs: ReactJS,
  android: Android,
  ios: iOS,
  ember: Ember,
  'team-up': TeamUp,
  'app-flow': AppFlow,
  'code-audit': CodeAudit,
  'project-management': ProjectManagement,
  'mobile-dev': MobileDev,
  'quality-assurance': QualityAssurance,
  'user-experience': UserExperience,
  'trending-flat': TrendingFlat,
}

export type IconName = keyof typeof icons

export default icons
