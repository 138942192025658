// extracted by mini-css-extract-plugin
export var typography = "typography-module--typography--v8dX3";
export var typographyAlignCenter = "typography-module--typography--align-center--a+l9A";
export var typographyAlignEnd = "typography-module--typography--align-end--8sYKY";
export var typographyAlignStart = "typography-module--typography--align-start--sHxfz";
export var typographyBody1 = "typography-module--typography--body1--TRN9A";
export var typographyBody2 = "typography-module--typography--body2--tkT94";
export var typographyColorInherit = "typography-module--typography--color-inherit--BFv2p";
export var typographyColorInitial = "typography-module--typography--color-initial--D90xO";
export var typographyColorTextSecondary = "typography-module--typography--color-text-secondary--x4IJC";
export var typographyGutterBottom = "typography-module--typography--gutter-bottom--Hh95R";
export var typographyGutterTop = "typography-module--typography--gutter-top--EoyZQ";
export var typographyH1 = "typography-module--typography--h1--EPQtq";
export var typographyH2 = "typography-module--typography--h2--FBzxs";
export var typographyH3 = "typography-module--typography--h3--8EOSo";
export var typographyH4 = "typography-module--typography--h4--ynGFj";
export var typographyH5 = "typography-module--typography--h5--lJBuR";
export var typographyH6 = "typography-module--typography--h6--O-Pqa";
export var typographyParagraph = "typography-module--typography--paragraph--XFFMx";