import classNames from 'classnames'
import { ButtonHTMLAttributes, ReactNode } from 'react'
import Link, { LinkProps } from '../../../link'
import { buttonBase } from './button-base.module.scss'

export interface ButtonBaseProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  children: ReactNode
  link?: Omit<LinkProps, 'children'>
}

const ButtonBase = ({
  link,
  children,
  className,
  onClick,
  ...rest
}: ButtonBaseProps) => {
  const classes = classNames(className, buttonBase)
  if (link)
    return (
      <Link {...link} className={classes} onClick={onClick}>
        {children}
      </Link>
    )
  return (
    <button type="button" {...rest} className={classes} onClick={onClick}>
      {children}
    </button>
  )
}

export default ButtonBase
