import useServices from '../../hooks/service'
import { useSiteMetadata } from '../../hooks/site'
import useWorks from '../../hooks/work'
import Container from '../container'
import Icon from '../icon'
import Link from '../link'
import Tooltip from '../tooltip'
import Typography from '../typography'
import {
  footer,
  footerContainer,
  footerContent,
  footerSocialLinks,
} from './footer.module.scss'

const Footer = () => {
  const work = useWorks()
  const services = useServices()
  const { name, social, contact } = useSiteMetadata()
  const columns = [
    {
      title: 'Work',
      links: work
        .slice(0, 3)
        .map(({ link: to, name: content }) => ({ to, content })),
    },
    {
      title: 'Services',
      links: services.slice(0, 3).map(({ link: to, name: content }) => ({
        to,
        content,
      })),
    },
  ]

  return (
    <footer className={footer} aria-labelledby="footer-navigation">
      <Container className={footerContainer}>
        <Link to="/" color="inherit">
          <Typography variant="h6" component="h2" color="inherit">
            {name}
          </Typography>
        </Link>

        <ul className={footerSocialLinks}>
          {social.map(({ link, icon, name: text }) => (
            <li key={link}>
              <Link href={link} external>
                <Tooltip text={text}>
                  <Icon name={icon} color="white" width={24} height={24} />
                </Tooltip>
              </Link>
            </li>
          ))}
        </ul>

        <div className={footerContent}>
          {columns.map(column => (
            <ul key={column.title}>
              <Typography gutterBottom variant="h6" color="inherit">
                {column.title}
              </Typography>
              {column.links.map(link => (
                <li key={link.to}>
                  <Link to={link.to} color="inherit">
                    {link.content}
                  </Link>
                </li>
              ))}
            </ul>
          ))}
          <ul>
            <Typography gutterBottom variant="h6" color="inherit">
              Contact
            </Typography>
            <li>
              <Link href={`tel:${contact.phoneNumber}`} color="inherit">
                {contact.formattedPhoneNumber}
              </Link>
            </li>
            <li>
              <Link href={`mailto:${contact.email}`} color="inherit">
                {contact.email}
              </Link>
            </li>
          </ul>
        </div>

        <Typography variant="body2" color="inherit">
          &copy; {new Date().getFullYear()} {name}
        </Typography>

        <Typography variant="body2" color="inherit" gutterTop>
          This site is protected by reCAPTCHA and the Google{' '}
          <Link
            external
            color="inherit"
            href="https://policies.google.com/privacy"
          >
            Privacy Policy
          </Link>{' '}
          and{' '}
          <Link
            external
            color="inherit"
            href="https://policies.google.com/terms"
          >
            Terms of Service
          </Link>{' '}
          apply.
        </Typography>
      </Container>
    </footer>
  )
}

export default Footer
