import classNames from 'classnames'
import Icon from '../../../icon'
import ButtonBase, { ButtonBaseProps } from '../button-base'
import { learnMoreButton } from './learn-more-button.module.scss'

export type LearnMoreButtonProps = Omit<ButtonBaseProps, 'children'>

const LearnMoreButton = ({ className, ...rest }: LearnMoreButtonProps) => (
  <ButtonBase className={classNames(className, learnMoreButton)} {...rest}>
    <span>Learn more</span>
    <Icon name="trending-flat" color="primary" size={32} />
  </ButtonBase>
)

export default LearnMoreButton
