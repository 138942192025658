import classNames from 'classnames'
import { ReactNode } from 'react'
import Element, { ElementProps } from '../element'
import { container, containerFluid } from './container.module.scss'

interface ContainerProps extends Pick<ElementProps, 'as'> {
  fluid?: boolean
  className?: string
  children: ReactNode
}

const Container = ({ as, fluid, children, className }: ContainerProps) => (
  <Element
    as={as}
    className={classNames(container, className, {
      [containerFluid]: fluid,
    })}
  >
    {children}
  </Element>
)

export default Container
