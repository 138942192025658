import { ReactNode } from 'react'
import { tooltip } from './tooltip.module.scss'

interface TooltipProps {
  text: ReactNode
  children: ReactNode
}

const Tooltip = ({ text, children }: TooltipProps) => (
  <span data-text={text} className={tooltip}>
    {children}
  </span>
)

export default Tooltip
