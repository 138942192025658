import { graphql, useStaticQuery } from 'gatsby'
import Service from '../../models/service'

type QueryResult = {
  allService: {
    edges: {
      node: Service
    }[]
  }
}

const useServices = (): Service[] => {
  const { allService } = useStaticQuery<QueryResult>(
    graphql`
      query {
        allService(sort: { fields: [position], order: ASC }) {
          edges {
            node {
              id
              icon
              name
              description
              position
              link: gatsbyPath(filePath: "/services/{Service.name}")
            }
          }
        }
      }
    `
  )
  return allService.edges.map(({ node }) => node)
}

export default useServices
