// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-[name]-tsx": () => import("./../../../src/pages/services/[name].tsx" /* webpackChunkName: "component---src-pages-services-[name]-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-service-name-tsx": () => import("./../../../src/pages/services/{Service.name}.tsx" /* webpackChunkName: "component---src-pages-services-service-name-tsx" */),
  "component---src-pages-work-[name]-tsx": () => import("./../../../src/pages/work/[name].tsx" /* webpackChunkName: "component---src-pages-work-[name]-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */),
  "component---src-pages-work-work-name-tsx": () => import("./../../../src/pages/work/{Work.name}.tsx" /* webpackChunkName: "component---src-pages-work-work-name-tsx" */)
}

