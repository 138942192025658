import classNames from 'classnames'
import { ReactNode } from 'react'
import {
  typography,
  typographyAlignCenter,
  typographyAlignEnd,
  typographyAlignStart,
  typographyBody1,
  typographyBody2,
  typographyColorInherit,
  typographyColorInitial,
  typographyColorTextSecondary,
  typographyGutterBottom,
  typographyGutterTop,
  typographyH1,
  typographyH2,
  typographyH3,
  typographyH4,
  typographyH5,
  typographyH6,
  typographyParagraph,
} from './typography.module.scss'

const STYLE_MAPPING = {
  h1: typographyH1,
  h2: typographyH2,
  h3: typographyH3,
  h4: typographyH4,
  h5: typographyH5,
  h6: typographyH6,
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: typographyBody1,
  body2: typographyBody2,
  body3: 'p',
}

const VARIANT_MAPPING = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
  body3: 'p',
}

interface TypographyProps {
  variant?: keyof typeof VARIANT_MAPPING
  paragraph?: boolean
  gutterTop?: boolean
  gutterBottom?: boolean
  align?: 'start' | 'center' | 'end'
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
  className?: string
  component?: React.ElementType
  children: ReactNode
}

const Typography = ({
  component,
  variant = 'body2',
  color,
  align,
  paragraph,
  gutterBottom,
  gutterTop,
  children,
  className,
}: TypographyProps) => {
  const classes = classNames(STYLE_MAPPING[variant], typography, className, {
    [typographyParagraph]: paragraph,
    [typographyGutterTop]: gutterTop,
    [typographyGutterBottom]: gutterBottom,
    [typographyColorInitial]: color === 'initial',
    [typographyColorInherit]: color === 'inherit',
    [typographyColorTextSecondary]: color === 'textSecondary',
    [typographyAlignStart]: align === 'start',
    [typographyAlignCenter]: align === 'center',
    [typographyAlignEnd]: align === 'end',
  })

  const Element =
    component ||
    (paragraph
      ? 'p'
      : (`${VARIANT_MAPPING[variant]}` as keyof JSX.IntrinsicElements))
  return <Element className={classes}>{children}</Element>
}

export default Typography
